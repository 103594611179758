export class Setting
{
  Name: string;
  Value: string;
  Sort: number;
  Section: string;
  Type: string;
  Title: string;
  Description: string;
  DefValue: string;
  Options: string;
  Zen: number;
  Min: number;
  Max: number;
  Step: number;
  Regex: string;
}
