import { ActivatedRoute, Params }   from '@angular/router';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {

    private _host = environment.host;
    constructor(private route: ActivatedRoute)
    {
        console.log("AppConfig constructor");
        this.route.queryParams.subscribe(params => {
            //console.log("Params = " + JSON.stringify(params));
            let host = params['host'];
            if (host != null && host.length > 0) {
                console.log("Changing to host: " + host + " from url query param.");
                this._host = host;
            }
        });
    }

    public host() : string {
        return this._host;
    }
}