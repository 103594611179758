import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { SoundService } from "./sound.service";
import { Sound } from "./sound";

@Component({    
    templateUrl: "./sound-edit-dialog.component.html",    
    styleUrls: [ "./sound-edit-dialog.component.css" ]    
})
export class SoundEditDialogComponent
{
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private soundService: SoundService,
        private dialogRef: MatDialogRef<SoundEditDialogComponent>
    ){
        this.sound = data as Sound;
        // edit a copy so the changes dont persist locally if user Cancels
        this.sound = JSON.parse(JSON.stringify(this.sound));
     }

    public sound: Sound;
   
    onSubmit(): void
    {
        console.log("submitting sound changes");

        if(this.sound.Tags)
        {
            this.sound.Tags = this.sound.Tags.trim().toLowerCase().replace(/\s+/g, ",");
        }

        //console.log("tags: " + this.sound.Tags);

        this.soundService.updateAdminSound(this.sound)
            .then(resp => {                                 
                this.dialogRef.close(resp); // return the updated sound to the caller
            })
            .catch(error => {
                console.error('An error occurred', error);                 
                return Promise.reject(error.message || error);
            });
    }
}