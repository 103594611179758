import { Component, OnInit, AfterViewChecked } from '@angular/core';

import { SoundService } from './sound.service';
import { environment } from 'environments/environment';
import { AppConfig } from './AppConfig';

@Component({
  selector: 'market-advanced',
  templateUrl: './advanced.component.html',
})

export class AdvancedComponent implements OnInit, AfterViewChecked
{
    title = "Advanced Console";
    cmd = "";
    terminal = "";
    working = false;

    constructor(private soundService: SoundService, private config: AppConfig) {
      this.terminal = "Enter Command (? = help) " + environment.version + " [" + this.config.host() + "]";
    }

    ngOnInit(): void
    {
    }

    add(msg : string)
    {
      this.terminal = this.terminal + "\n" + msg;   
      this.working = false; 
    }

    err(error : any)
    {
      this.add("ERROR: " + error.Message + "\n");
      this.working = false;
    }

    ngAfterViewChecked() 
    {        
      var textarea = document.getElementById('terminal');
      textarea.scrollTop = textarea.scrollHeight + 100;     
    } 

    run(c : string)
    {
      if (c.length > 0)
      {
        this.working = true;
        this.add("> " + c);
        this.soundService.runAdminFixup(c).then( result => this.add(result + "\n")).catch( error => this.err( error ));
      }
    }

    submit()
    {
      console.log("Command: " + this.cmd);
      this.run(this.cmd);
      this.cmd = "";
    }

    // flush cache
    flush()
    {
      this.run("cache flush");
    }

}
