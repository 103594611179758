// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.child-content {
	width:100%;
	height:100%;
}
.center-container {
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

table {
	width: 100%;
	margin-left:auto;
	margin-right:auto;
}

.page-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-buttons > button {
    margin: 5px;
}

.small-button {
    min-width: 50px;
    max-width: 80px;
}

.download-button {
	display: inline-block;
	font-size: 9pt;
	height: 35px;
	color: white;
	background-color: #3744AF;
}

.app-toolbar-search 
{
  height: 100%;
  color: #333;
  border: 1px solid #D0D0D0;
  border-radius: 100px;
  background-color: #fafafa;
  padding: 5px 16px;

  flex: 1 1 auto;

  min-width: 120px;
  max-width: 300px;

  outline: none;
}

.mat-column-download {
	width: 100px;
	padding: 0px !important; /* required to properly center the buttons in this column */
	text-align: center;
	vertical-align: center;
	/* background-color: green; */
}

.mat-column-sound_status {
	width: 70px;
	padding: 0px !important; /* required to properly center the buttons in this column */
	text-align: center;
	vertical-align: center;
	/* background-color: green; */
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
