import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyChipsModule as MatChipModule, MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { MatExpansionModule } from '@angular/material/expansion';

//import 'hammerjs';

// table
import { CdkTableModule } from '@angular/cdk/table';

// toast
//import {ToastModule} from 'ng2-toastr/ng2-toastr';

// cookies
import { CookieModule } from 'ngx-cookie';

// auth ( https://github.com/ronzeidman/ng2-ui-auth )
import { AppConfig } from './AppConfig';
import { HttpClientModule } from '@angular/common/http';
import { MarketAuth } from './marketauth.service';

// auth protection
import { AuthGuard } from './auth.guard';

// service
import { SoundService } from './sound.service';
//import { ErrorService } from './error.service';

// app
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { SoundsComponent } from './sounds.component';
import { CommentsComponent } from './comments.component';
import { UsersComponent } from './users.component';
import { TagsComponent } from './tags.component';
import { NotificationsComponent } from './notifications.component';
import { AdvancedComponent } from './advanced.component';
import { TokenComponent } from './token.component';
import { YesNoDialogComponent } from './yes-no-dialog.component';
import { TagAddDialogComponent } from './tag-add-dialog.component';
import { SoundEditDialogComponent } from './sound-edit-dialog.component';
import { SoundAttachDialogComponent } from './sound-attach-dialog.component';
import { SoundAddDialogComponent } from './sound-add-dialog.component';
import { UserEditDialogComponent } from './dialog/user-edit-dialog.component';
import { NotificationSendDialogComponent } from './notification-send-dialog.component';
import { SettingDialogComponent } from './settings/setting-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionsComponent } from './subscriptions.component';
import { StoreComponent } from './store/store/store.component';
import { TMApiService } from './store/tmapi.service';
import { StoreDetailsDialogComponent } from './store/store-details-dialog/store-details-dialog.component';
import { PromoDialogComponent } from './store/promo-dialog/promo-dialog.component';
import { PricingDialogComponent } from './store/pricing-dialog/pricing-dialog.component';
import { LogComponent } from './log/logs.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';

const routes: Routes = [
  { path: '',              component: HomeComponent },
  { path: 'sounds',        component: SoundsComponent, canActivate: [AuthGuard] },
  { path: 'comments',      component: CommentsComponent, canActivate: [AuthGuard] },
  { path: 'users',         component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'tags',          component: TagsComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'settings',      component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard] },
  { path: 'advanced',      component: AdvancedComponent, canActivate: [AuthGuard] },
  { path: 'token/:token',  component: TokenComponent },
  { path: 'store',         component: StoreComponent },
  { path: 'log',           component: LogComponent },
];

@NgModule({
    declarations: [
        AppComponent, HomeComponent, SoundsComponent, CommentsComponent, UsersComponent, TagsComponent, NotificationsComponent,
        AdvancedComponent, TokenComponent, YesNoDialogComponent, TagAddDialogComponent, SoundEditDialogComponent, SoundAttachDialogComponent,
        SoundAddDialogComponent, UserEditDialogComponent, NotificationSendDialogComponent, SettingDialogComponent, SettingsComponent, 
        SubscriptionsComponent, StoreComponent, StoreDetailsDialogComponent, PromoDialogComponent, PricingDialogComponent, LogComponent
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule, FormsModule, CdkTableModule,
        //ToastModule.forRoot(),
        RouterModule.forRoot(routes),
        CookieModule.forRoot(),
        HttpClientModule,
        MatListModule, MatSidenavModule, MatCardModule, MatMenuModule, MatDialogModule, MatTabsModule, 
        MatToolbarModule, MatTableModule, MatButtonModule, MatInputModule, MatFormFieldModule, 
        MatProgressBarModule, MatRadioModule, MatCheckboxModule, MatIconModule, MatSelectModule, 
        MatOptionModule, MatLegacyChipsModule, MatExpansionModule,
        NgxChartsModule
    ],
    providers: [SoundService, AuthGuard, MarketAuth, TMApiService, AppConfig],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule { }
