import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { Sound } from './sound';
import { SoundService } from './sound.service';
import { AppConfig } from './AppConfig';

import { YesNoDialogComponent } from './yes-no-dialog.component';

import { SoundEditDialogComponent } from './sound-edit-dialog.component';
import { SoundAttachDialogComponent } from './sound-attach-dialog.component';
import { SoundAddDialogComponent } from './sound-add-dialog.component';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';


@Component({
  selector: 'market-sounds',
  templateUrl: './sounds.component.html',
  styleUrls: [ './sounds.component.css' ]  
})
export class SoundsComponent implements OnInit
{
  constructor(
    private soundService: SoundService,
    private dialog: MatDialog,
    public config: AppConfig) { }

  // database table
//  displayedColumns = ['Image', 'Label', 'State', 'Version', 'UploadDate', 'RecordDate', 'Hearts', 'Comments', 'Downloads', 'Minutes', 'Description', 'Tags'];
  displayedColumns = ['Image', 'Sound', 'Dates', 'Stats', 'Tags', 'Description', 'Download', 'Actions'];
  soundsDatabase = new SoundsDatabase();
  dataSource: SoundsDataSource | null;

  @ViewChild(MatTabGroup, { static: true }) tabControl: MatTabGroup;

  states = ["", "0", "1", "2", "5", "10", "11", "14", "15", "16", "17", "19", "30", "99" ];

  title = "Sounds";
  working = false;
  soundCount = 0;
  lastStateIndex = 0;
  search = "";
  lastSearch = "";
  curOffset = 0;
  curLimit = 25;

  // sorting by  Time, Downloads, Minutes Listened, Comments
  sortOptions = [
    { value: "UploadDate", label: "Date" },
    { value: "Downloads", label: "Downloads" },
    { value: "Hearts", label: "Hearts" },
    { value: "Comments", label: "Comments" },
    { value: "Minutes", label: "Minutes" },
  ];
  
  private _sort = "UploadDate";
  get sort() : string { return this._sort; }
  set sort(value: string)
  {
    this._sort = value;
    if(this.lastSearch) this.getSearchSounds();
    else this.getSounds(this.states[this.lastStateIndex]);
  }

  ngOnInit(): void
  {    
    this.dataSource = new SoundsDataSource(this.soundsDatabase);
    this.getSounds("");
  }

  getSounds(state : string)
  {
    if (this.working)
    {
      console.error("Already getting sounds!");
      return;
    }
    console.log("Getting sounds for state: " + state);
    this.working=true;
    this.soundCount = 0;
    this.soundsDatabase.dataChange.next([]);
    this.soundService.getAdminSounds(state, this.sort, this.curLimit, this.curOffset)
      .then(sounds => this.setSounds(sounds))
      .catch(err => this.setError(err));
  }

  searchSounds() : void
  {
    if (this.working)
    {
      console.error("Already getting sounds!");
      return;
    }
    if(!this.search)
    {
      console.error("No search term entered.");
      return;
    }
    // clear any selected state and reset the tab control
    this.lastStateIndex = 0;
    if(this.tabControl) this.tabControl.selectedIndex = 0;
    // reset offset to start
    this.curOffset = 0;
    this.getSearchSounds();
  }

  getSearchSounds() : void
  {
    if (this.working)
      {
        console.error("Already getting sounds!");
        return;
      }
      if(!this.search)
      {
        console.error("No search term entered.");
        return;
      }
      console.log("Search sounds for query: " + this.search);
      this.working = true;
      this.soundCount = 0;
      this.soundsDatabase.dataChange.next([]);
      this.lastSearch = "";

      // perform search and call setSounds with results
      this.soundService.getAdminSearchSounds(this.search, this.sort, this.curLimit, this.curOffset)
        .then(sounds =>{ 
          this.lastSearch = this.search;
          return this.setSounds(sounds);
        })
        .catch(err => this.setError);
  }

  pageSounds(forward: boolean): void
  {
    this.curOffset += (forward ? 1 : -1) * this.curLimit;
    if(this.curOffset < 0) this.curOffset = 0;

    if(this.lastSearch)
    {
      this.getSearchSounds();
    }
    else
    {
      this.getSounds(this.states[this.lastStateIndex]);
    }
  }

  setSounds(sounds: Sound[])
  {
    this.working = false;

    this.soundCount = sounds.length;

    // break up tags
    var that = this;
    sounds.forEach(function(sound)
    {
      that.convertTags(sound);
    });

    // change data
    this.soundsDatabase.dataChange.next(sounds);
  }

  setError(err: any)
  {
    console.info("Error during query! Logging err...");
    console.log(err);
    this.working = false;
  }

  convertTags(sound: Sound)
  {
    if(sound.Tags && sound.Tags.length)
    {
      sound.Tags = sound.Tags.replace(/,/g," ");        
    }
  }

  addSound()
  {
    console.log("add sound dialog opened");
    this.dialog.open(SoundAddDialogComponent)
      .afterClosed()
      .subscribe(result => {
        console.log("Add Sound Result=" + JSON.stringify(result));
          if(result)
          {
            this.curOffset = 0;
            this.lastStateIndex = 0;
            this.getSounds(this.states[0]);
            this.tabControl.selectedIndex = 0;
            // // refresh results to retrieve any new data
            // if(this.lastSearch) this.getSearchSounds();
            // else this.getSounds(this.states[this.lastStateIndex]);
          }
      });
  }

  // actions
  editSound(row: any)
  {
    console.log("Edit sound: " + row.Label + " (" + row.Uid + ")");
    this.dialog.open(SoundEditDialogComponent, { data: row })
      .afterClosed()
      .subscribe(result => {
        console.log("Result=" + JSON.stringify(result));
        if(result)
        {
          // replace the old row with the updated one
          var rowIndex = this.soundsDatabase.data.indexOf(row);
          this.convertTags(result as Sound);
          this.soundsDatabase.data[rowIndex] = result;
          this.soundsDatabase.dataChange.next(this.soundsDatabase.data);
        }
      });
  }

  attachSound(row: any)
  {
    console.log("Attach sound: " + row.Label + " (" + row.Uid + ")");
    this.dialog.open(SoundAttachDialogComponent, {data: row})
      .afterClosed()
      .subscribe(result => {
        if(result)
        {
          console.log(result);//JSON.stringify(result));
          let updatedSound = result.updatedSoundData;

          // // refresh results to retrieve any new data
          // if(this.lastSearch) this.getSearchSounds();
          // else this.getSounds(this.states[this.lastStateIndex]);

          // replace the old row with the updated one
          var rowIndex = this.soundsDatabase.data.indexOf(row);          
          this.convertTags(result as Sound);
          this.soundsDatabase.data[rowIndex] = updatedSound as Sound;
          this.soundsDatabase.dataChange.next(this.soundsDatabase.data);
        }
      });
  }

  deleteSound(row: any)
  {
    console.log("Delete sound: " + row.Label + " (" + row.Uid + ")");
    this.dialog.open(YesNoDialogComponent, { 
      width: "400px",
      data: {
        title: "Delete " + row.Label,
        message: "Are you sure you wish to delete this sound?"
      }
    }).afterClosed()
      .subscribe(result => {
        if (result) this.soundService.deleteAdminSound(row.Uid)
          .then(delResult => {
            // redo search or get-items so we have the correct number of items in our results
            if(this.lastSearch) this.getSearchSounds();
            else this.getSounds(this.states[this.lastStateIndex]);
          })
          .catch(err => console.error("Error during delete: " + err));
      });
  }

  setState(state : number)
  {    
    if(this.working)
    {
      // change selected tab back to the current request
      if(this.tabControl) this.tabControl.selectedIndex = this.lastStateIndex;
      return;
    }
    console.log("Select Change: " + state);
    this.search = "";
    this.lastSearch = "";
    this.lastStateIndex = state;
    this.curOffset = 0;

    this.getSounds(this.states[state]);
  }

  getAdminDownloadUrl(row: Sound, fileType: string): string
  {
    return this.soundService.getAdminDownloadUrl(row.Uid, fileType);
  }

  // onDownload(row: Sound, fileType: string)
  // {
  //   var filename = row.Uid + "." + fileType;
  //   this.soundService.getAdminDownload(row.Uid, fileType)
  //     .then(response => this.downloadFile(response, filename))
  //     .catch(err => console.error("Error during download " + fileType + ": " + err));
  // }

  // downloadFile(data: any, filename: string){
  //   var blob = new Blob([data], { type: 'application/octet-stream' });
  //   importSaveAs(blob, filename);
  //   // var url = window.URL.createObjectURL(blob);
  //   // window.open(url);
  // }
}

/** Sound database that the data source uses to retrieve data for the table. */
export class SoundsDatabase 
{
  /** Stream that emits whenever the data has been modified. */
  //dataChange: BehaviorSubject<UserData[]> = new BehaviorSubject<UserData[]>([]);
  //get data(): UserData[] { return this.dataChange.value; }
  dataChange: BehaviorSubject<Sound[]> = new BehaviorSubject<Sound[]>([]);
  get data(): Sound[] { return this.dataChange.value; }
  
  constructor() 
  {
  }
}

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, SoundsDatabase. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
export class SoundsDataSource extends DataSource<any> 
{
  constructor(private _soundsDatabase: SoundsDatabase) 
  {
    super();
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Sound[]> 
  {
    return this._soundsDatabase.dataChange;
  }

  disconnect() {}
}

