import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { SoundService } from './sound.service';
import { YesNoDialogComponent } from './yes-no-dialog.component';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/startWith';
//import 'rxjs/add/observable/merge';
//import 'rxjs/add/operator/map';

// data
import { Comment } from './comment';
import { AppConfig } from './AppConfig';

@Component({
  selector: 'market-comments',
  templateUrl: './comments.component.html',
  styles: [
    `
    .heart-select {
      color: red;
    }
    .mat-button {
      margin: 0 auto 0 0;
      padding: 0;
      min-width: 0;
      width: 100%;
      max-width: 160px;
    }
    .cdk-column-Actions { min-width: 80px; }
    .cdk-column-Message { padding-right: 14px; }
    .mat-row { padding: 10px 20px; }
    `
  ]
})

export class CommentsComponent implements OnInit
{
    title = "Comments";
    working = false;
    toggling = false;

    // data table
    displayedColumns = ['Sound', 'User', 'Message', 'Sent', 'Actions'];
    database = new CommentDatabase();
    dataSource: CommentDataSource | null;
  
    constructor(
      private soundService: SoundService,
      private dialog: MatDialog,
      public config: AppConfig)
    {}

    ngOnInit(): void
    {
      this.working = true;
      this.dataSource = new CommentDataSource(this.database);
      this.soundService.getAdminComments().then(data => this.setData(data)).catch(err => this.setError(err));
    }
  
    setData(data: Comment[])
    {
      this.working = false;
      this.database.dataChange.next(data);
    }
  
    setError(err: any)
    {
      console.info("Error during query!", err);
      this.working = false;
    }

    toggleHeart(row: Comment): void
    {
      if(this.toggling)
      {
        console.log("already toggling heart please wait a moment");
      }
      console.log("toggle heart for comment " + row.CommentUid);
      this.toggling = true;
      this.soundService.setFlag(row.SoundUid, row.CommentUid, !row.UserHeart)
        .then(result =>{
          this.toggling = false;
          if(!result.Success)
          {
            console.error("Failed to toggle heart.");
            return;
          }
          row.UserHeart = !row.UserHeart;
          row.Hearts = result.Total;
          this.setData(this.database.data);
        })
        .catch(err => this.setError(err));
    }

    deleteComment(row: Comment): void
    {
      console.log("requesting delete of comment id=" + row.CommentUid);

      let commentTrimmed = row.Message.length < 50 ? row.Message : (row.Message.substring(0,50) + "...");
      let options = {
        data: {
          title: "Delete Comment",
          message: "Delete the '" + commentTrimmed + "' comment?"  
        }
      };

      this.dialog.open(YesNoDialogComponent, options)
        .afterClosed()
        .subscribe(result => {
          
          if(!result) return;

          this.soundService.deleteAdminComment(row.SoundUid, row.CommentUid)
          .then(response => {
            console.log(response);
            var ix = this.database.data.indexOf(row);
            if(ix >= 0)
            {
              this.database.data.splice(ix, 1);
            }
            this.setData(this.database.data);
          })
          .catch(err => { this.toggling = false; return this.setError(err) });  
        });

    }
}

export class CommentDatabase 
{
  dataChange: BehaviorSubject<Comment[]> = new BehaviorSubject<Comment[]>([]);
  get data(): Comment[] { return this.dataChange.value; }
  
  constructor() {}
}

export class CommentDataSource extends DataSource<any> 
{
  constructor(private _database: CommentDatabase) 
  {
    super();
  }

  connect(): Observable<Comment[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

