import { Component, Inject } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TMApiService } from '../tmapi.service';

@Component({
  selector: 'app-pricing-dialog',
  templateUrl: './pricing-dialog.component.html',
  styleUrls: ['./pricing-dialog.component.css']
})

export class PricingDialogComponent {

  title="Add Pricing";
  error = "";
  edit=false;

  duration=0;
  price=0.0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tmapiService: TMApiService,
    private dialogRef: MatDialogRef<PricingDialogComponent>) 
    { 
      if (data)
      {
        //console.log("EDIT = " + JSON.stringify(data));
        this.title = "Edit Pricing";
        this.duration = data.duration;
        this.price = data.price;
        this.edit = true;
      }
    }

    onClose()
    {
      // nop for result right now
      this.dialogRef.close();
    }

    onSubmit() 
    {
      this.tmapiService.putAdminPrice(this.duration, this.price)
                      .then( data => this.dialogRef.close(data))
                      .catch(error => {
                          console.error("An error occurred", error);
                          this.error = error;
                        }
                      );      
    }

    onRemove()
    {
      if (this.duration == 0)
      {
        this.error = "Invalid id for removal.";
        return;
      }

      this.tmapiService.deleteAdminPrice(this.duration)
                      .then( data => this.dialogRef.close(data))
                      .catch(error => console.error("An error occurred", error));    
    }

    clearError() 
    {
      this.error="";
    }
}
