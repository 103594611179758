import { Component, OnInit } from '@angular/core';

import { SoundService } from './sound.service';
import { AppConfig } from './AppConfig';

// cdk table
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

// data
import { Subscription } from './subscription';

@Component({
  selector: 'market-subscriptions',
  templateUrl: './subscriptions.component.html',
})

export class SubscriptionsComponent implements OnInit
{
    title = "Subscriptions";
    working = false;

    paging = false;
    offset = 0;
    limit = 100;
    count = 0;

    // data table
    displayedColumns = ['Order', 'LongDates', 'Data'];
    database = new SubscriptionDatabase();
    dataSource: SubscriptionDataSource | null;
  
    constructor(
      private soundService: SoundService,
      public config: AppConfig) { }

    ngOnInit(): void
    {
      this.dataSource = new SubscriptionDataSource(this.database);
      this.getData();
    }

    page(forward: boolean): void {
      this.offset += (forward ? 1 : -1) * this.limit;
      if (this.offset < 0) this.offset = 0;

      this.paging = true;
      this.getData();
    }

    getData(): void {
      console.log("refreshing data");
      this.working = true;
      this.soundService.getAdminSubscriptions(this.offset, this.limit)
        .then(subs => {
          this.setData(subs)
          this.working = false;
          this.paging = false;
        })
        .catch(err => { 
          this.setError(err)
          this.working = false;
          this.paging = false;
        });      
    }
  
    setData(subs: Subscription[]) {
      this.count = !subs || subs.length == 0 ? 0 : subs.length;
      this.database.dataChange.next(subs);
    }
  
    setError(err: any)
    {
      console.error("Error during query: ", err);
      this.working = false;
    }
}

export class SubscriptionDatabase 
{
  dataChange: BehaviorSubject<Subscription[]> = new BehaviorSubject<Subscription[]>([]);
  get data(): Subscription[] { return this.dataChange.value; }
  
  constructor() {}
}

export class SubscriptionDataSource extends DataSource<any> 
{
  constructor(private _database: SubscriptionDatabase) 
  {
    super();
  }

  connect(): Observable<Subscription[]> 
  {
    return this._database.dataChange;
  }

  disconnect() {}
}

