// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.app-toolbar-search 
{
  height: 100%;
  color: #333;
  border: 1px solid #D0D0D0;
  border-radius: 100px;
  background-color: #fafafa;
  padding: 5px 16px;

  flex: 1 1 auto;

  min-width: 120px;
  max-width: 300px;
  width: auto;

  outline: none;

  font: 13.333px Arial;
}

/* fixes for material breaking the search toolbar on some pages */
.app-toolbar-search::placeholder { 
  color: #757575 !important;
  opacity: 1 !important;
}
.app-toolbar-search::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #757575 !important;
  opacity: 1 !important;
}
.app-toolbar-search::-moz-placeholder { /* Firefox 19+ */
  color: #757575 !important;
  opacity: 1 !important;
}
.app-toolbar-search:-ms-input-placeholder { /* IE 10+ */
  color: #757575 !important;
  opacity: 1 !important;
}

.all-tab-link
{
    display: flex;
    flex-direction: row; 
    justify-content: center; 
    align-items: center; 
    width: 100%;
    height: 100%; 
    text-decoration: none;
    color: inherit;
}

.page-buttons
{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-buttons > button
{
    margin: 5px;
}

.cdk-column-Download {
    max-width: 70px;
    text-align: center;
}

.sort-form {
  margin: 16px;
  font-size: 10pt;
  max-width: 100px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
