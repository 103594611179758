import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SoundService } from 'app/sound.service';
import { MarketAuth } from 'app/marketauth.service';

@Injectable()
export class TMApiService {

  private downloadEndpoint = "/market/download.php";
  private adminEndpoint = "/market/admin.php";

  private settings: any;

  constructor(
    private http: HttpClient,
    private auth: MarketAuth,
    private adminService: SoundService)
  {
  }

  // -------------- general queries --------------
  async init(): Promise<any>
  {
    return this.getSettings();
  }

  async getSettings(): Promise<any>
  {
    try
    {
      let results = await this.adminService.getAdminSettings();

      this.settings = {};
      results.forEach(setting =>
      {
        this.settings[setting.Name] = setting.Value;
      });
      return Promise.resolve(this.settings);
    }
    catch (err)
    {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  // helper to get api URLs
  getApiUrl(endpoint: string) : string
  {
    var host = (this.settings != null) ? this.settings["store.api"] : null;
    if (host == null || host.length == 0)
    {
      console.log("WARNING: No valid api host found in settings so using environment config.");
      host = environment.api;

    }
    return host + endpoint;
  }

  getToken() : string
  {
    if (!this.auth) return "";
    return this.auth.getToken();
  }

  syncSounds(): Promise<any>
  {
    if (this.isAuthorized)
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url += "?sounds=sync";

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }
      return this.http.get(url)
                 .toPromise()
                 .then(this.parseResult)
                 .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  getDownloadBlob(downloadId: string): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getDownloadUrl(downloadId);

      //console.log("DOWNLOAD = " + url);
      return this.http.get(url, { responseType: 'blob' })
                      .toPromise()
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  getApiDownloadUrl(): string
  {
    return this.getApiUrl(this.downloadEndpoint);
  }

  getDownloadUrl(downloadId: string): string
  {
    // process order on server to complete it
    var url = this.getApiDownloadUrl();
    url += url.indexOf("?") >= 0 ? "&" : "?";
    url += "id="+downloadId;

    let token: string = this.auth.getToken();
    if (token != null && token.length > 0)
    {
      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "token="+token;
    }

    return url;
  }

  getRawDownloadUrl(downloadId: string): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getApiDownloadUrl();

      // process order on server to complete it
      url += url.indexOf("?") >= 0 ? "&" : "?";
      url += "qid="+downloadId;

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      //console.log("DOWNLOAD = " + url);
      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  deleteAdminPromo(name: string)
  {
    if (this.isAuthorized)
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url += "?promo&delete="+name;

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  putAdminPromo(name: string, value:number, enabled: boolean): Promise<any>
  {
    if (this.isAuthorized)
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url += "?promo";

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      let body = {
        "id": name,
        "value": value,
        "enabled": enabled
      };

      return this.http.put(url, body)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }


  putAdminPrice(duration: number, price: number): Promise<any>
  {
    if (this.isAuthorized)
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url += "?pricing";

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      let body = {
        "duration": duration,
        "price": price
      };

      return this.http.put(url, body)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  deleteAdminPrice(duration: number): Promise<any>
  {
    if (this.isAuthorized)
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url += "?pricing&delete="+duration;

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  getAdmin(name: string, offset: number, limit: number): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url += "?"+name+"&offset="+offset+"&limit="+limit;

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  getAdminSearch(name: string, search: string, offset: number, limit: number): Promise<any>
  {
    if (this.isAuthorized())
    {
      let url = this.getApiUrl(this.adminEndpoint);
      url +=  "?"+name+"&search="+encodeURIComponent(search)+"&offset="+offset+"&limit="+limit;

      let token: string = this.auth.getToken();
      if (token != null && token.length > 0)
      {
        url += url.indexOf("?") >= 0 ? "&" : "?";
        url += "token="+token;
      }

      return this.http.get(url)
                      .toPromise()
                      .then(this.parseResult)
                      .catch(this.handleError);
    }
    else
    {
      return Promise.reject("Not authorized.");
    }
  }

  isAuthorized(): boolean
  {
    let token: string = this.auth.getToken();
    return token != null && token.length > 0;
  }

  fixPromoCode(str: string): string
  {
    if (str == null) return "";
    return str.replace(/\s/g,'')
              .toUpperCase();

  }

  haveFileInfo(sound: any): boolean
  {
    if (!sound) return false;
    if (!sound["FileInfo"]) return false;

    let fileInfo = JSON.parse(sound["FileInfo"]);
    if (!fileInfo || fileInfo.length == 0) return false;

    let result = true;
    fileInfo.forEach(info =>
    {
      if (!info.size || info.size == 0)
      {
        result = false;
      }
    });
    return result;
  }

  getFileInfo(sound: any): string
  {
    if (sound == null) return "";
    let duration = sound["Duration"];
    let fileInfo = JSON.parse(sound["FileInfo"]);

    var info = "";
    if (duration.length > 0)
    {
      let hours = duration / 60 / 60;
      let hrsLabel = (hours == 1 ? "Hour" : "Hours");
      info += hours+" "+hrsLabel;
    }

    // see if we can get the file size info since we know the duration
    if (fileInfo && fileInfo.length > 0)
    {
      var index=-1;
      for (var i = 0; i < fileInfo.length; i++)
      {
        let file = fileInfo[i];
        if (file.duration == duration)
        {
          index = i;
          break;
        }
      }
      if (index >= 0)
      {
        let file = fileInfo[index];
        info += " - "+ Math.round(file.size/1024/1024).toFixed(0)+"MB";
      }
    }
    return info;
  }

  private parseResult(response: any)
  {
    if (response && response["result"]==true)
    {
      var results = response["results"];
      return results;
    }
    else
    {
      return Array();
    }
  }

  private handleError(err: any): Promise<any>
  {
    console.error('An error occurred', err);

    // pull out the most specific error message possible (market returns error.Message and firebase returns error.message)
    var msg : string = null;
    if (err.error)
    {
      // tmio server uses error as a string itself
      if (typeof err.error == 'string')
      {
        msg = err.error;
      }
      else
      {
        msg = err.error.Message || err.error.message;
      }
    }
    if (msg == null)
    {
        msg = err.Message || err.message || err.statusText;
    }
    if (msg == null)
    {
        msg = "Unknown error occurred.";
    }

    // pass along the best error message to the application
    return Promise.reject(msg);
  }
}
